




















import BlogArticleDialogVue from "@/components/BlogArticleDialog.vue";
import BlogDataTableVue from "@/components/BlogDataTable.vue";
BlogArticleDialogVue;
import Vue from "vue";

export default Vue.extend({
  components: {
    BlogDataTableVue,
    BlogArticleDialogVue,
  },
  async beforeMount() {
    await this.$store.dispatch("getBlogArticles");
    await this.$store.dispatch("getCategories", "blog");
  },
  data() {
    return {
      editIndex: 0,
      showDialog: false,
    };
  },
  methods: {
    addArticle() {
      this.editIndex = 0;
      this.showDialog = true;
    },
    editArticle(id: number) {
      this.editIndex = id;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.editIndex = 0;
    },
  },
});
