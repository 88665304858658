












import BlogArticle from "@/store/models/BlogArticle";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      headers: [
        {
          text: "",
          value: "cover",
          width: 130,
        },
        {
          text: "Catégorie",
          value: "Category.name",
          width: 300,
        },
        {
          text: "Titre",
          value: "title",
        },
        {
          text: "",
          value: "tools",
          align: "end",
          width: 60,
        },
      ] as DataTableHeader[],
    };
  },
  methods: {
    openItem(itemClicked: BlogArticle) {
      this.$emit("edit-article", itemClicked.id);
    },
    processCurrentItems(articles: BlogArticle[]) {
      this.processing = true;
      console.log(articles);
      this.processing = false;
    },
  },
});
