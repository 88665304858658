






























































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import rules from "@/modules/rules";
import { MediaType } from "@/store/interfaces/MediaType";
import BlogArticle from "@/store/models/BlogArticle";
import Vue from "vue";
import { VueEditor, VueEditorMethods } from "vue2-editor";
enum BlogDialogMode {
  EDIT,
  CREATE,
}
const emptyBlogArticle: BlogArticle = {
  id: 0,
  title: "",
  caption: "",
  htmlContent: "",
};
const toolbar = [
  [{ header: [false, 3, 4, 5, 6] }],
  ["bold", "italic", "underline"], // toggled buttons
  [{ align: "" }, { align: "center" }, { align: "justify" }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  ["link", "image"],
  ["clean"], // remove formatting button
];

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default Vue.extend({
  components: {
    VueEditor,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    buttonText() {
      if (this.mode == BlogDialogMode.CREATE) {
        return "Ajouter";
      } else {
        return "Sauvegarder";
      }
    },
    coverFile: {
      get(): File | null {
        return this.cover as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.cover = value;
              this.coverPicSrc = URL.createObjectURL(value);
              this.coverChanged = true;
            } else {
              this.cover = null;
              this.coverPicSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.cover = value;
            this.coverPicSrc = base64Images.thumbnailPlaceholder;
            this.coverChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.blogObjectLoaded = false;
          this.coverFile = null;
          this.categoryId = 0;
          this.blogObject = { ...emptyBlogArticle };
          (this.$refs.addBlogForm as any).resetValidation();
          this.blogObjectChanged = false;
          this.coverChanged = false;
          this.$emit("close");
        }
      },
    },
    cardTitle() {
      if (this.mode == BlogDialogMode.CREATE) {
        return "Ajouter un article de blog";
      } else {
        return "Éditer l'article";
      }
    },
  },
  data() {
    return {
      valid: false,
      mode: BlogDialogMode.CREATE,
      blogObject: { ...emptyBlogArticle },
      blogObjectLoaded: false,
      blogObjectChanged: false,
      blogObjectLoading: false,
      categoryId: 0,
      coverPicSrc: base64Images.thumbnailPlaceholder,
      cover: null as null | File,
      coverChanged: false,
      rules: rules(),
      toolbar: toolbar,
      loading: false,
    };
  },
  methods: {
    resetDialog() {
      //tbd
      // we need to delete all the temporary images from the backend if we're in create mode
      if (this.mode == BlogDialogMode.CREATE && !this.id) {
        const htmlContentDom = new DOMParser().parseFromString(
          this.blogObject.htmlContent,
          "text/html"
        );
        const uploadedImages = htmlContentDom.querySelectorAll("p img");
        for (const image of uploadedImages) {
          const imageSource = image.getAttribute("src");
          if (imageSource) {
            this.handleImageRemoved(imageSource);
          }
        }
      }
      this.showDialog = false;
    },
    selectImage() {
      (this.$refs.coverSelector as any).$refs.input.click();
    },
    validateThumbnail(file: File, sizeInKb = 300) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < sizeInKb * 1000) {
          return true;
        } else {
          throw {
            message: `Le fichier est trop volumineux, sa taille doit être inférieure à ${sizeInKb}ko`,
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
    async handleImageAdded(
      file: File,
      Editor: any,
      cursorLocation: any,
      resetUploader: () => void
    ) {
      try {
        if (this.validateThumbnail(file, 200)) {
          const form = new FormData();
          form.append("media", file);
          form.append(
            "body",
            JSON.stringify({
              type: MediaType.blog,
            })
          );
          const response = await Request.shared.post("media", form);
          const imageUrl = `${process.env.VUE_APP_API_HOST}/content/blog/media/${response.data.filename}`;
          Editor.insertEmbed(cursorLocation, "image", imageUrl);
          if (this.mode == BlogDialogMode.EDIT && this.id) {
            // In edit mode we've got no choice, as the article exists, we need to update its content no matter what as
            // images are stored in backend
            const response = await Request.shared.put(
              `blogarticle/${this.id}`,
              {
                htmlContent: this.blogObject.htmlContent,
              }
            );
          }
        }
        resetUploader()
      } catch (error) {
        this.$store.dispatch("showAlert", {
          message: error.message,
          color: "warning",
          timeout: 4000,
        });
      }
    },
    async handleImageRemoved(filepath: string) {
      if (
        this.mode == BlogDialogMode.EDIT &&
        this.id &&
        this.blogObjectLoaded
      ) {
        // In edit mode we've got no choice, as the article exists, we need to update its content no matter what as
        // images are stored in backend
        const response = await Request.shared.put(`blogarticle/${this.id}`, {
          htmlContent: this.blogObject.htmlContent,
        });
      }
      // We must delete the file IF we're not currently closing the window
      if (this.blogObjectLoaded || this.mode == BlogDialogMode.CREATE) {
        const filepathSplitted = filepath.split("/content/blog/media/");
        if (filepathSplitted.length == 2) {
          const filename = filepathSplitted[1];
          // we can delete the source by filename, as it must be a blog type object
          Request.shared.delete("media", {
            data: {
              filename,
            },
          });
        }
      }
    },
    handleButtonClick() {
      switch (this.mode) {
        case BlogDialogMode.EDIT:
          console.log("updating article");
          this.updateBlogArticle();
          return;
        case BlogDialogMode.CREATE:
          console.log("creating article");
          this.createBlogArticle();
          return;
      }
    },
    async createBlogArticle() {
      this.loading = true;
      try {
        const buffer = { ...this.blogObject };
        delete buffer.id;

        const response = await Request.shared.post("blogarticle", buffer);
        buffer.id = response.data.id;

        // we check if there is a category
        if (this.categoryId) {
          // we assign it
          await Request.shared.put(
            `blogarticle/${buffer.id}/category/${this.categoryId}`
          );
        }

        // we now check if there is a coverpic
        if (this.coverChanged) {
          // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
          if (this.coverFile) {
            // The user has set a new thumbnail, we need to create the media and assign it to the video
            const mediaBody = {
              type: MediaType.cover,
              alt: this.coverFile.name,
            };
            // We create the formdata
            const formData = new FormData();
            formData.append("body", JSON.stringify(mediaBody));
            formData.append("media", this.coverFile);
            const media = await Request.shared.post("media", formData);
            // Once we got the media, we can assign the thumbnail to the video
            const assignedThumbnail = await Request.shared.put(
              `blogarticle/${buffer.id}/coverpic/${media.data.id}`
            );
          }
        }
        this.$store.dispatch("getBlogArticles");
        this.showDialog = false;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async updateBlogArticle() {
      this.loading = true;
      try {
        const buffer = { ...this.blogObject };

        const response = await Request.shared.put(
          `blogarticle/${buffer.id}`,
          buffer
        );

        // we check if there is a category
        if (this.categoryId) {
          // we assign it
          await Request.shared.put(
            `blogarticle/${buffer.id}/category/${this.categoryId}`
          );
        } else {
          // We send a request without the parameter to unassign the category (should'nt happen)
          await Request.shared.put(`blogarticle/${buffer.id}/category`);
        }

        // we now check if there is a coverpic
        if (this.coverChanged) {
          // If the user has deleted the thumbnail, we send the appropriate request (an empty thumbnail route)
          if (this.coverFile) {
            // The user has set a new thumbnail, we need to create the media and assign it to the video
            const mediaBody = {
              type: MediaType.cover,
              alt: this.coverFile.name,
            };
            // We create the formdata
            const formData = new FormData();
            formData.append("body", JSON.stringify(mediaBody));
            formData.append("media", this.coverFile);
            const media = await Request.shared.post("media", formData);
            // Once we got the media, we can assign the thumbnail to the video
            const assignedThumbnail = await Request.shared.put(
              `blogarticle/${buffer.id}/coverpic/${media.data.id}`
            );
          } else {
            await Request.shared.put(`blogarticle/${buffer.id}/coverpic`);
          }
        }
        this.$store.dispatch("getBlogArticles");
        this.showDialog = false;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async getBlogArticle() {
      this.blogObjectLoading = true;
      try {
        const response = Request.shared.get(`blogarticle/${this.id}`);
        const buffer = (await response).data as BlogArticle;

        // we spread the buffer in the object
        this.blogObject = { ...buffer };
        // We get the coverpic if exists
        if (buffer.CoverPic) {
          const pic = (
            await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${buffer.CoverPic.filename}`,
              { responseType: "blob" }
            )
          ).data;
          this.coverPicSrc = URL.createObjectURL(pic);
        }
        if (buffer.Category) {
          this.categoryId = buffer.Category.id;
        }
        // object is loaded
        setTimeout(() => {
          this.blogObjectLoaded = true;
        }, 100);
      } catch (error) {
        console.error(error);
      }
      this.blogObjectLoading = false;
    },
    handleDrop(e: DragEvent) {
      if (e.dataTransfer!.files.length) {
        this.coverFile = e.dataTransfer!.files[0];
      }
    },
  },
  beforeMount() {
    if (this.show) {
      // in case of a reload
      this.mode = this.id ? BlogDialogMode.EDIT : BlogDialogMode.CREATE;
      if (!this.id) {
        // It means we are already loaded
        this.blogObjectLoaded = true;
      } else {
        // we get the blog article, get the cover
        this.getBlogArticle();
      }
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.mode = this.id ? BlogDialogMode.EDIT : BlogDialogMode.CREATE;
        if (!this.id) {
          // It means we are already loaded
          this.blogObjectLoaded = true;
        } else {
          // we get the blog article, get the cover
          this.getBlogArticle();
        }
      }
    },
    blogObject: {
      deep: true,
      handler() {
        if (this.blogObjectLoaded) {
          this.blogObjectChanged = true;
        }
      },
    },
    blogObjectChanged() {
      console.log(`MAINTENANT : ${this.blogObjectChanged}`);
    },
  },
});
